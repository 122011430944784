import React, {useEffect} from 'react';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import {navigate} from 'gatsby-link';

const Checkout = () => {
  useEffect(() => {
    navigate('/');
  }, []);

  return (
    <div className="content-wrapper">
      <PageHero title="" />
      <Layout></Layout>
    </div>
  );
};

export default Checkout;
